th, td {
    border: 5px solid #30404d;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    width: 25%;
}
table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}